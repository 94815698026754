/* src/components/HeroSection.css */

/* Ajuste para dispositivos com largura máxima de 375px (ex: iPhone 6/7/8) */
@media (max-width: 375px) {
    .hero-section {
      background-position: 50% 30%; /* Ajuste vertical para focar no "young millionaires" */
      background-size: cover; /* Mantém a cobertura completa */
    }
  }
  
  /* Ajuste para dispositivos com largura de 376px a 767px (ex: Tablets) */
  @media (min-width: 376px) and (max-width: 767px) {
    .hero-section {
      background-position: 50% 40%; /* Posição intermediária para tablets */
      background-size: cover;
    }
  }
  
  /* Ajuste para dispositivos com largura de 768px e acima (ex: Desktops) */
  @media (min-width: 768px) {
    .hero-section {
      background-position: center; /* Centraliza a imagem */
      background-size: cover;
    }
  }
  