/* src/styles/globals.css */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  background-color: #000000;
}

a {
  text-decoration: none;
}
